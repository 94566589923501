@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

/* Apply font family globally */
body {
  font-family: "Open Sans", sans-serif;
  /* font-size: 20px; */
  /* font-weight: 300; */
  /* line-height: 27.24px; */
  background-color: #c6bdb4;
}
::selection {
  background-color: rgba(0, 50, 0, 0.01);
}
